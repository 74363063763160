// Startseite-Slider

$(document).ready(function(){
    $('.fade').slick({
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: false,
        infinite: true,
        speed: 500,
        fade: true,
        cssEase: 'linear'
    });
});

// Fahnen und Masten >> Werbetechik

$('.slider-unterseite').slick({
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: '<button type="button" class="slick-prev"></button>',
    nextArrow: '<button type="button" class="slick-next"></button>',
    responsive: [
        {
            breakpoint: 600,
            settings: {

                arrows: false,
                autoplay: true,
                autoplaySpeed: 2000
            }
        }
    ]
});

// Active-menu

$('#main-nav ul li:has(ul)').addClass('has-subnav');

$('#main-nav ul li.has-subnav').mouseover(function() {
    $(this).addClass('active');
    $('.overlay').addClass('menu-active');
    $('body').addClass('no-scroll');
});

$('#main-nav ul li.has-subnav').mouseout(function() {
    $(this).removeClass('active');
    $('.overlay').removeClass('menu-active');
    $('body').removeClass('no-scroll');
});


// Press "ESC"
$(document).keyup(function (e) {
    if (e.keyCode == 27) {
        $(".header-mainnav, .bookmarks-toggle, .search-wrapper, .search-close").removeClass("search-active");
        $(".overlay").removeClass("active menu-active search-active bookmarks-active open-position-active off-canvas-active");
        $(".hamburger").removeClass("is-active");
        $("#off-canvas").removeClass("active");
        $("#off-canvas").removeClass("off-canvas-active");
        $(".filter-select-wrapper ul").removeClass("open");
        $("body").removeClass("no-scroll no-scroll-search no-scroll-bookmarks no-scroll-off-canvas");
        $("#main-nav ul li.has-subnav").removeClass("active");
        $("#main-nav ul li").removeClass("active");
        $('.dropdown-box').removeClass('dropdown-active');
        $('.active-hover').removeClass('active-c');

    }
});
$(".overlay").click(function () {
    $(".overlay").removeClass("active open-position-active off-canvas-active");
    $(".hamburger").removeClass("is-active");
    $("#off-canvas").removeClass("active");
    $("body").removeClass("no-scroll no-scroll-search no-scroll-bookmarks no-scroll-off-canvas");
});
// OffCanvas
$(".off-canvas-toggle").click(function () {
    $(".hamburger").toggleClass("is-active");
    $("#main-wrapper, .overlay").toggleClass("off-canvas-active");
    $("#off-canvas").toggleClass("active");
    $("body").toggleClass("no-scroll-off-canvas");
    var offCanvasHeight = $(".off-canvas-wrapper").height();
    $(".off-canvas-wrapper").css("height", offCanvasHeight);
});

$('.acc-menu > ul > li:has(ul)').addClass("has-subnav");
$('.acc-menu > ul > li > a').click(function() {
    $(this).closest('li').toggleClass('open') ;
});

// Select all links with hashes
$('a[href*="#"]')
// Remove links that don't actually link to anything
.not('[href="#"]')
    .not('[href="#0"]')
    .click(function(event) {
        // On-page links
        if (
            location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '')
            &&
            location.hostname == this.hostname
        ) {
            // Figure out element to scroll to
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            // Does a scroll target exist?
            if (target.length) {
                // Only prevent default if animation is actually gonna happen
                event.preventDefault();
                if ($(window).width() > 800) {
                    $('html, body').animate({
                        scrollTop: target.offset().top - 110
                    }, 1500);
                } else {
                    $('html, body').animate({
                        scrollTop: target.offset().top - 75
                    }, 1500);
                }
            }
        }
    });


$('#off-canvas-nav-main > ul > li > ul > li:has(ul)').addClass('has-subnav');


$('#off-canvas-nav-main > ul > li > ul > li:has(ul)').click(function() {
    $(this).toggleClass('active');
});


$("#off-canvas-nav-main > ul > li > ul > li ").click(function () {
    $(this).toggleClass('open');
});

